import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Section, { SectionTitle, SectionText } from "../components/Section"

const RegistryPage = () => (
  <Layout title="Registry">
    <Section id="registry">
      <Container pad>
        <SectionTitle>Gift your presence!</SectionTitle>
        <SectionText>
          <p>
            The only gift we are still looking for is time with you all. Please
            just show up ready to celebrate!
          </p>
          <p>
            If you're really itching to send a gift, please donate to a charity
            of your choice in our name. Some of our favorites are:
            <ul>
              <li>
                <a href="https://www.trailkeepersoforegon.org/">
                  Trailkeepers of Oregon
                </a>
              </li>
              <li>
                <a href="https://www.aclu-or.org/">
                  ACLU of Oregon or your local chapter
                </a>
              </li>
            </ul>
          </p>
        </SectionText>
      </Container>
    </Section>
  </Layout>
)

export default RegistryPage
